<template>
  <div id="contact-view" style="margin-top: 7em">
    <div class="d-flex justify-content-center my-4">
      <h1>CONTACT US</h1>
    </div>
    <div class="container" v-if="loader === false">
      <form class="my-1">
        <div class="form-row">
          <div class="g-recaptcha" data-sitekey=""></div>
        </div>
        <div class="g-recaptcha" data-sitekey="your_site_key"></div>
        <div class="row col-md-12 d-flex justify-content-center">
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.first_name }"
                v-model="first_name"
                aria-describedby="emailHelp"
                placeholder="First Name*"
              />
              <span class="text-danger" v-if="errors.first_name">{{
                this.errors.first_name[0]
              }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.last_name }"
                v-model="last_name"
                aria-describedby="emailHelp"
                placeholder="Last Name*"
              />
              <span class="text-danger" v-if="errors.last_name">{{
                this.errors.last_name[0]
              }}</span>
            </div>
          </div>
        </div>
        <div class="row col-md-12 d-flex justify-content-center">
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.mobile }"
                v-model="mobile"
                placeholder="Mobile*"
              />
              <span class="text-danger" v-if="errors.mobile">{{
                this.errors.mobile[0]
              }}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                :class="{ 'is-invalid': errors.email }"
                v-model="email"
                placeholder="E Mail*"
              />
              <span class="text-danger" v-if="errors.email">{{
                this.errors.email[0]
              }}</span>
            </div>
          </div>
        </div>
        <div class="row col-md-12 d-flex justify-content-center">
          <div class="col-md-8">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.subject }"
                v-model="subject"
                placeholder="Subject*"
              />
              <span class="text-danger" v-if="errors.subject">{{
                this.errors.subject[0]
              }}</span>
            </div>
          </div>
        </div>
        <div class="row col-md-12 d-flex justify-content-center">
          <div class="col-md-8">
            <div class="form-group">
              <textarea
                class="form-control"
                :class="{ 'is-invalid': errors.message }"
                placeholder="Message*"
                v-model="message"
                rows="4"
              ></textarea>
              <span class="text-danger" v-if="errors.message">{{
                this.errors.message[0]
              }}</span>
            </div>
          </div>
        </div>

        <div class="row col-md-12 d-flex justify-content-center">
          <div class="col-md-8 text-right my-4">
            <button
              type="button"
              @click="saveContactForm"
              class="btn btn-warning"
            >
              Submit Message
            </button>
          </div>
        </div>
      </form>
    </div>
    <div>
      <b-modal
        ref="my-modal"
        size="lg"
        hide-footer
        centered
        hide-header
        title="Using Component Methods"
      >
        <div class="d-block text-center ">
          <img src="@/assets/site/images/balls.png" class="my-3" alt />
          <button
            type="button"
            class="close"
            @click="hideModal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3>Submission Sent!</h3>
          <div>
            <p class="text-center">
              We have recieved your<br />
              submission. <br />Thank you
            </p>
            <br />
          </div>
        </div>
        <center>
          <!-- <b-button class="btn btn-primary my-2 col-md-4"  block @click="hideModal">Return Home</b-button> -->
          <router-link class="" to="/">
            <button class="btn btn-warning my-2  text-light">
              Return Home
            </button>
          </router-link>
        </center>
      </b-modal>
    </div>
  </div>
</template>

<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script>
export default {
  name: "ContactForm",
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Get in touch with us through our contact form. Easily submit your details and messages, and receive prompt feedback. Your inquiries are important to us!",
      },
      {
        name: "keywords",
        content:
          "Contact form, Submit message, Customer inquiries, Feedback form",
      },
    ],
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      mobile: null,
      subject: null,
      email: null,
      message: null,
      loader: false,
      errors: [],
    };
  },
  methods: {
    saveContactForm() {
      //Save or update User Details
      this.errors = [];
      // this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/saveContactForm";
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          // Authorization: "Bearer " + $('meta[name="api_token"]').attr("content")
        },
        body: JSON.stringify({
          first_name: this.first_name,
          last_name: this.last_name,
          mobile: this.mobile,
          subject: this.subject,
          email: this.email,
          message: this.message,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.resetForm();
            this.$swal("Success", data.message, "success");
            this.resetForm();
            // this.showModal();
          } else if (data.status === false) {
            this.$swal("Error", data.message, "error");
          } else {
            this.errors = data.errors;
          }
          this.loader = false;
        })
        .catch(function(err) {
          console.log("Conatct Data", "Error : " + err.message, "error");
        });
    },
    resetForm() {
      this.first_name = null;
      this.last_name = null;
      this.mobile = null;
      this.subject = null;
      this.email = null;
      this.message = null;
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>
